import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

export const initSentry = () => {
	if (process.env.SENTRY_DSN_LOGIN) {
		Sentry.init({
			dsn: process.env.SENTRY_DSN_LOGIN,
			environment: process.env.ENVIRONMENT || 'development',
			release: process.env.BUILD_VERSION,

			integrations: [
				new Sentry.BrowserTracing({
					// See docs for support of different versions of variation of react router
					// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
					routingInstrumentation: Sentry.reactRouterV6Instrumentation(
						React.useEffect,
						useLocation,
						useNavigationType,
						createRoutesFromChildren,
						matchRoutes
					),
				}),
			],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			tracesSampleRate: 1.0,
		});
	}
};
