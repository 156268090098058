import {
	useMutation,
	UseMutationOptions,
	UseMutationResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';

export interface GetTokensForPasswordPayload {
	grant_type: 'password';
	email: string;
	password: string;
	client_id: string;
	client_secret: string;
}

export interface GetTokensForAuthorizationCodePayload {
	grant_type: 'authorization_code';
	code: string;
	redirect_uri: string;
	state: string;
}

type GetTokensPayload = GetTokensForPasswordPayload | GetTokensForAuthorizationCodePayload;

export type GetTokensResponse = {
	access_token: string;
	refresh_token: string;
};

const AUTH_URL = process.env.AUTH_URL as string;

const doGetTokens = async (body: GetTokensPayload, init?: RequestInit): Promise<GetTokensResponse> => {
	const response = await fetch(`${AUTH_URL}/auth/tokens`, {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
		...init,
	});

	if (!response.ok) {
		throw new Error(`Unable to get the tokens: ${response.status}`);
	}

	return response.json() as Promise<GetTokensResponse>;
};

export const useDoGetTokensMutation = (
	options?: UseMutationOptions<GetTokensResponse, unknown, GetTokensPayload>
): UseMutationResult<GetTokensResponse, unknown, GetTokensPayload, unknown> => {
	return useMutation<GetTokensResponse, unknown, GetTokensPayload>(['get-tokens'], doGetTokens, options);
};

export const useDoGetTokensQuery = (
	payload: GetTokensPayload,
	options?: UseQueryOptions<GetTokensResponse, Error>
): UseQueryResult<GetTokensResponse, Error> => {
	return useQuery<GetTokensResponse, Error>(
		['get-tokens'],
		({ signal }) => doGetTokens(payload, { signal }),
		options
	);
};
