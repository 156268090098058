import { RefObject, useCallback, useRef } from 'react';

import { useEventListener } from './use-event-listener.hook';

export const useClickOutside = (
	domRef: RefObject<HTMLElement>,
	callback: (event: Event) => void,
	shouldAttach = true
): void => {
	const callbackRef = useRef(callback);

	const handleClickOutSide = useCallback(
		(event: Event) => {
			if (!domRef?.current?.contains(event.target as Element) && callbackRef.current) {
				callbackRef.current(event);
			}
		},
		[domRef]
	);

	useEventListener('click', handleClickOutSide, shouldAttach, document, true);
};
