import { UseQueryOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { DiscoveryResponse, useDoDiscovery } from '../../api/discovery.api';
import { useDoInitiate, useDoInitiateWithSso } from '../../api/initiate.api';
import { Step } from '../../App';

export const useInitiateAuthentication = () => {
	const navigateTo = useNavigate();

	return useDoInitiate({
		onSuccess: () => {
			navigateTo(Step.CONFIRM_EMAIL);
		},
	});
};

export const useInitiateSsoAuthentication = () => {
	const navigateTo = useNavigate();

	return useDoInitiateWithSso({
		onSuccess: (response) => {
			if (response.redirectUrl) {
				window.location.replace(response.redirectUrl);
				return;
			}

			navigateTo(Step.CONFIRM_EMAIL);
		},
	});
};

export const useDiscoverWorkspaces = (options?: UseQueryOptions<DiscoveryResponse>) => {
	const navigateTo = useNavigate();

	return useDoDiscovery(
		{},
		{
			onSuccess: (data: DiscoveryResponse) => {
				options?.onSuccess?.(data);

				navigateTo(Step.SELECT_WORKSPACE);
			},
		}
	);
};
