import { useSessionStorage } from '@cheqroom/hooks';
import { useNavigate } from 'react-router-dom';

import { DiscoverOrganisationsResponse, Organisation, useDoOrganisationsDiscovery } from '../../api/organisations.api';
import { Step } from '../../App';

export interface SelectOrganisationsData {
	isLoading: boolean;
	organisations: Organisation[];
	selectOrganisation: (organisation: Organisation) => void;
}

export const useDiscoverOrganisations = (): SelectOrganisationsData => {
	const navigateTo = useNavigate();

	const [, saveOrganisationInSession] = useSessionStorage<string>('organisation');

	const organisationDiscoveryQuery = useDoOrganisationsDiscovery<DiscoverOrganisationsResponse>({
		cacheTime: 0,
		retry: false,
		onSuccess: (data) => {
			data.organisations.length === 1 && selectOrganisation(data.organisations[0]);
			return data;
		},
		onError: () => {
			navigateTo(Step.ENTER_EMAIL, { replace: true });
		},
	});

	const selectOrganisation = ({ id }: Organisation) => {
		saveOrganisationInSession(id);
		navigateTo(Step.SELECT_WORKSPACE, { replace: true, state: { organisationId: id } });
	};

	return {
		isLoading: organisationDiscoveryQuery.isLoading,
		organisations: organisationDiscoveryQuery.data?.organisations || [],
		selectOrganisation,
	};
};
