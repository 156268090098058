import tw, { styled } from 'twin.macro';

import { Props } from './Content';

export const StyledArrow = styled.div`
	${tw`absolute w-4 h-4`}
	z-index:-1;

	&:after {
		${tw`absolute left-0 w-4 h-4 transform rotate-45 bg-gray-900`}
		content: ' ';
	}
`;

export const StyledContentWrapper = styled.div<Props>`
	${tw`min-w-max`}

	${({ fullWidth }) => fullWidth && tw`w-full`}

	&[data-popper-placement^='top'] > ${StyledArrow} {
		bottom: -3px;
	}
	&[data-popper-placement^='bottom'] > ${StyledArrow} {
		top: -3px;
	}
	&[data-popper-placement^='left'] > ${StyledArrow} {
		right: -3px;
	}
	&[data-popper-placement^='right'] > ${StyledArrow} {
		left: -3px;
	}
`;
