import 'twin.macro';

import { useTimeout, useToggle } from '@cheqroom/hooks';
import { Cross, Error, Info, Warning } from '@cheqroom/icons';
import { FC, ReactNode } from 'react';

import { Icon, IconButton, ProgressBar, Stack, Text, TextStyle } from '../..';
import { getIconColor, StyledBanner } from './Banner.styles';

export type Variation = 'success' | 'warning' | 'error' | 'info' | 'default';
export type Direction = 'auto' | 'row' | 'column' | 'auto-icon';
export type IconAlignment = 'center' | 'top' | 'bottom';

export type Props = {
	children: ReactNode;
	direction?: Direction;
	alignIcon?: IconAlignment;
	variation: Variation;
	squared?: boolean;
	closeAfter?: number;
	title?: string;
};

const mapVariationToIcon = (variation: Variation) => {
	switch (variation) {
		case 'warning':
			return Warning;
		case 'error':
			return Error;
		default:
			return Info;
	}
};

export const Banner: FC<Props> = ({
	children,
	variation = 'info',
	direction = 'auto',
	alignIcon = 'center',
	squared = false,
	closeAfter = undefined,
	title,
	...props
}) => {
	const canClose = closeAfter !== undefined ? closeAfter >= 0 : false;
	const willClose = canClose && (closeAfter ?? 0) > 0;
	const { onToggle, toggled } = useToggle(true);
	useTimeout(() => (willClose ? onToggle() : null), closeAfter ?? 0);

	if (!toggled) return null;

	return (
		<StyledBanner squared={squared} variation={variation} direction={direction} alignIcon={alignIcon} {...props}>
			{willClose && (
				<div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
					<ProgressBar max={closeAfter ?? 0} count={0} timer />
				</div>
			)}
			<Icon color={getIconColor(variation)} source={mapVariationToIcon(variation)} tw="flex-shrink-0" />
			<Stack tw="w-full" vertical spacing="extraTight">
				{title && <TextStyle variation="strong">{title}</TextStyle>}
				<Text color="current">{children}</Text>
			</Stack>
			{canClose && <IconButton source={Cross} onClick={onToggle} variation="content-only" />}
		</StyledBanner>
	);
};
