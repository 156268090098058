import { useSessionStorage } from '@cheqroom/hooks';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useRecentWorkspaces } from '../../api/find-workspaces.api';
import { useDoGetTokensQuery } from '../../api/get-tokens.api';
import { useDoFetchSsoTokens } from '../../api/get-tokens-for-sso-login.api';
import { Step } from '../../App';

const APP_URL = process.env.APP_URL as string;

export const useLoginWithOAuthCode = () => {
	const [params] = useSearchParams();
	const navigateTo = useNavigate();
	const [isMobileApp] = useSessionStorage<boolean>('mobile');
	const [redirect_uri, _, clearStorage] = useSessionStorage<string>('CHEQROOM_REDIRECT_KEY');
	const { add: addRecentWorkspace } = useRecentWorkspaces();
	const { t } = useTranslation();

	// Get the params from the url and use them to identify with the python API
	const code = params.get('code');
	const state = params.get('state');

	return useDoFetchSsoTokens(
		{ code: code!, state: state!, redirectUri: `${window.location.origin}${Step.SSO_REDIRECT}` },
		{
			enabled: !!code && !!state,
			onSuccess: ({ accessToken, refreshToken }) => {
				const decodedToken = jwtDecode<{ workspace: string }>(accessToken);

				addRecentWorkspace(decodedToken.workspace);

				if (isMobileApp) {
					window.location.replace(`/mobile.html?access_token=${accessToken}&session_token=${refreshToken}`);
				} else {
					const url = new URL(`${APP_URL}/${decodedToken.workspace}`);
					url.searchParams.append('access_token', accessToken);
					url.searchParams.append('session_token', refreshToken);

					if (redirect_uri) {
						url.searchParams.append('redirect_uri', redirect_uri);
					}

					clearStorage();
					window.location.replace(url);
				}
			},
			onError: (error) => {
				return navigateTo(Step.SELECT_WORKSPACE, {
					state: {
						error: error?.message.includes('403')
							? t('sso_redirect_step.no_access')
							: t('sso_redirect_step.sso_failed'),
					},
				});
			},
		}
	);
};

export const useNewLoginWithOauthCode = () => {
	const [params] = useSearchParams();
	const navigateTo = useNavigate();
	const [isMobileApp] = useSessionStorage<boolean>('mobile');
	const [redirect_uri, _, clearStorage] = useSessionStorage<string>('CHEQROOM_REDIRECT_KEY');

	const { t } = useTranslation();

	// Get the params from the url and use them to identify with the python API
	const code = params.get('code');
	const state = params.get('state');

	return useDoGetTokensQuery(
		{
			code: code!,
			state: state!,
			redirect_uri: `${window.location.origin}${Step.SSO_REDIRECT}`,
			grant_type: 'authorization_code',
		},
		{
			enabled: !!code && !!state,
			onSuccess: ({ access_token, refresh_token }) => {
				const decodedToken = jwtDecode<{ workspace: string }>(access_token);

				if (isMobileApp) {
					window.location.replace(`/mobile.html?access_token=${access_token}&session_token=${refresh_token}`);
				} else {
					const url = new URL(`${APP_URL}/${decodedToken.workspace}`);
					url.searchParams.append('access_token', access_token);
					url.searchParams.append('session_token', refresh_token);

					if (redirect_uri) {
						url.searchParams.append('redirect_uri', redirect_uri);
					}

					clearStorage();
					window.location.replace(url);
				}
			},
			onError: (error) => {
				return navigateTo(Step.SELECT_WORKSPACE, {
					state: {
						error: error?.message.includes('403')
							? t('sso_redirect_step.no_access')
							: t('sso_redirect_step.sso_failed'),
					},
				});
			},
		}
	);
};
