import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

const AUTH_TOKEN_URL = process.env.AUTH_TOKEN_URL as string;

interface PythonErrorResponse {
	error: string;
	error_description: string;
}

export interface AuthenticateRequest {
	username: string;
	password: string;
	workspaceId?: string;
}

export interface AuthenticateResponse {
	status_code: number;
	access_token: string;
	token_type: string;
	refresh_token: string;
}

export class PythonRequestError extends Error {
	constructor(
		public readonly error: string,
		public readonly description: string,
		public readonly status: number
	) {
		super(error);
	}
}

const doAuthenticate = async ({
	username,
	password,
	workspaceId,
}: AuthenticateRequest): Promise<AuthenticateResponse> => {
	const response = await fetch(`${AUTH_TOKEN_URL}`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			grant_type: 'password',
			username,
			password,
			...(workspaceId ? { workspace_id: workspaceId } : {}),
		}),
	});

	if (!response.ok) {
		const err = (await response.json()) as PythonErrorResponse;
		throw new PythonRequestError(err.error, err.error_description, response.status);
	}

	return response.json() as Promise<AuthenticateResponse>;
};

export const useDoAuthenticate = (
	options?: UseMutationOptions<AuthenticateResponse, PythonRequestError, AuthenticateRequest>
): UseMutationResult<AuthenticateResponse, PythonRequestError, AuthenticateRequest, unknown> => {
	return useMutation<AuthenticateResponse, PythonRequestError, AuthenticateRequest, unknown>(
		['authenticate'],
		doAuthenticate,
		options
	);
};
