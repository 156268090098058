import { useClickOutside, useToggle } from '@cheqroom/hooks';
import { Modifier, Placement, PositioningStrategy } from '@popperjs/core';
import { Options as FlipOptions } from '@popperjs/core/lib/modifiers/flip';
import { Options as OffsetOptions } from '@popperjs/core/lib/modifiers/offset';
import { Options as OverflowOptions } from '@popperjs/core/lib/modifiers/preventOverflow';
import { Children, cloneElement, FC, isValidElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import useBodyClass from '../../hooks/useBodyClass';
import { useKeyPress } from '../../hooks/useKeyPress';
import { ESCAPE_KEY_CODE } from '../../util/keyCodes';
import { Overlay } from '../Overlay/Overlay';
import { Content } from './components/Content/Content';
import { StyledArrow } from './components/Content/Content.styles';
import { Trigger } from './components/Trigger/Trigger';
import { PopoverDispatchContext, PopoverStateContext } from './Popover.context';
import { StyledPopover } from './Popover.styles';

export interface PopoverComposition {
	Content: typeof Content;
	Trigger: typeof Trigger;
}

export interface Props {
	/** Show backdrop */
	backdrop?: boolean;
	/** Callback on closed */
	onClose?: () => void;
	/** Callback on toggled */
	onToggle?: () => void;
	onToggleChange?: (toggled: boolean) => void;
	/** Placement */
	placement?: Placement;
	/** Popover is toggled */
	toggled?: boolean;
	/** Strategy */
	strategy?: PositioningStrategy;
	/** Modifiers */
	modifiers?: (
		| Partial<Modifier<'offset', OffsetOptions>>
		| Partial<Modifier<'flip', FlipOptions>>
		| Partial<Modifier<'preventOverflow', OverflowOptions>>
	)[];
	/** Custom popover wrapper */
	renderWrapper?: () => typeof StyledPopover;
	children?: ReactNode;
	/** Show arrow */
	showArrow?: boolean;
}

const Popover: FC<Props> & PopoverComposition = ({
	backdrop = false,
	children,
	onClose: controlledOnClose,
	onToggle: controlledOnToggle,
	onToggleChange,
	placement = 'auto',
	toggled: controlledToggled,
	showArrow = false,
	modifiers = [
		{
			name: 'offset',
			options: {
				offset: [0, 5],
			},
		},
	],
	strategy = 'absolute',
	renderWrapper = () => StyledPopover,
}) => {
	const popoverRef = useRef<HTMLDivElement>(null);
	const triggerRef = useRef<HTMLElement>(null);
	const arrowRef = useRef<HTMLDivElement>(null);
	const [contentRef, setContentRef] = useState<HTMLElement>();

	const isControlled = !!controlledOnClose && !!controlledOnToggle && controlledToggled !== undefined;

	const { onClose: unControlledOnClose, onToggle: unControlledOnToggle, toggled: unControlledToggled } = useToggle();

	const handleOnClose = useCallback(() => {
		if (isControlled) {
			controlledOnClose?.();
		} else {
			unControlledOnClose();
		}
	}, [controlledOnClose, isControlled, unControlledOnClose]);

	const handleOnToggle = useCallback(() => {
		if (isControlled) {
			controlledOnToggle?.();
		} else {
			unControlledOnToggle();
		}
	}, [controlledOnToggle, isControlled, unControlledOnToggle]);

	const toggled = useMemo(
		() => (isControlled ? controlledToggled : unControlledToggled),
		[controlledToggled, isControlled, unControlledToggled]
	);

	onToggleChange?.(toggled);

	// Add helper class to body if popover with backdrop is used
	useBodyClass('popover-open', backdrop && toggled);

	// Only attach the event listeners if the popover is toggled. This will prevent calling the onClose function whenever the Popover component
	// is mounted but not toggled. If you have logic in your onClose function, then it will only be called if the popover is actually open.
	useClickOutside(popoverRef, handleOnClose, toggled);

	useKeyPress({
		handlers: [
			{
				targetKey: ESCAPE_KEY_CODE,
				onDown: handleOnClose,
			},
		],
		shouldAttach: toggled,
	});

	const { styles, attributes } = usePopper(triggerRef?.current, contentRef, {
		placement,
		strategy,
		modifiers: [
			...modifiers,
			...(showArrow
				? [
						{
							name: 'arrow',
							options: {
								element: arrowRef.current,
							},
						},
					]
				: []),
		],
	});

	const arrow = showArrow ? <StyledArrow ref={arrowRef} style={styles.arrow} /> : null;

	const childrenWithProps = Children.map(children, (child) => {
		if (isValidElement(child)) {
			if (child.type === Trigger) {
				return cloneElement(child, { ref: triggerRef });
			}

			if (child.type === Content) {
				return cloneElement(child, {
					...attributes.popper,
					arrow,
					ref: setContentRef,
					style: styles.popper,
				});
			}
		}
		return child;
	});

	const PopoverWrapper = renderWrapper();

	return (
		<PopoverStateContext.Provider value={toggled}>
			<PopoverDispatchContext.Provider value={{ onClose: handleOnClose, onToggle: handleOnToggle }}>
				<PopoverWrapper ref={popoverRef} toggled={toggled}>
					{childrenWithProps}
				</PopoverWrapper>

				{backdrop && toggled && <Overlay />}
			</PopoverDispatchContext.Provider>
		</PopoverStateContext.Provider>
	);
};

Popover.Content = Content;
Popover.Trigger = Trigger;

export { Popover };
