import { FC, ReactNode } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { CookieErrorPage } from '../CookieErrorPage/CookieErrorPage';

enum BoundaryError {
	OTHER = 'other',
	COOKIES = 'cookies',
}

const getBrowserError = ({ message }: Error): BoundaryError => {
	const isFirefoxCookieError = message === 'The operation is insecure.';
	const disabledCookies = !navigator.cookieEnabled || isFirefoxCookieError;

	if (disabledCookies) return BoundaryError.COOKIES;

	return BoundaryError.OTHER;
};

const ErrorFallback = ({ error }: FallbackProps) => {
	if (!(error instanceof Error)) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre>{error}</pre>
			</div>
		);
	}

	const boundaryError = getBrowserError(error);

	switch (boundaryError) {
		case BoundaryError.COOKIES:
			return <CookieErrorPage />;
		case BoundaryError.OTHER:
		default: {
			return (
				<div role="alert">
					<p>Something went wrong:</p>
					<pre>{error.message}</pre>
				</div>
			);
		}
	}
};

export const GlobalErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => (
	<ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
);
