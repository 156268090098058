import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export interface SsoRequest {
	code: string;
	state: string;
	redirectUri: string;
}

export interface SsoResponse {
	accessToken: string;
	refreshToken: string;
}

const AUTH_URL = process.env.AUTH_URL as string;

export const doFetchSsoTokens = async (
	{ code, redirectUri, state }: SsoRequest,
	init?: RequestInit
): Promise<SsoResponse> => {
	const response = await fetch(`${AUTH_URL}/auth/tokens/sso`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		credentials: 'include',
		body: JSON.stringify({
			code,
			state,
			redirect_uri: redirectUri,
		}),
		...init,
	});

	if (!response.ok) {
		throw new Error(`Unable to fetch sso: ${response.status}`);
	}

	return response.json() as Promise<SsoResponse>;
};

export const useDoFetchSsoTokens = (
	{ code, state, redirectUri }: SsoRequest,
	options?: UseQueryOptions<SsoResponse, Error>
): UseQueryResult<SsoResponse, Error> => {
	return useQuery<SsoResponse, Error>(
		['sso', code, state],
		({ signal }) => doFetchSsoTokens({ code, state, redirectUri }, { signal }),
		options
	);
};
