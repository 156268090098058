import { ImgHTMLAttributes, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

type NativeImageProps = ImgHTMLAttributes<HTMLImageElement>;

export interface UseImageProps {
	/**
	 * The image `src` attribute
	 */
	src?: string | null;
	/**
	 * A callback for when the image `src` has been loaded
	 */
	onLoad?: NativeImageProps['onLoad'];
	/**
	 * A callback for when there was an error loading the image `src`
	 */
	onError?: NativeImageProps['onError'];
}

type Status = 'loading' | 'failed' | 'pending' | 'loaded';

type ImageEvent = SyntheticEvent<HTMLImageElement, Event>;

/**
 * React hook that loads an image in the browser,
 * and lets us know the `status` so we can show image
 * fallback if it is still `pending`
 *
 * @returns the status of the image loading progress
 *
 * @example
 *
 * ```jsx
 * function App(){
 *   const status = useImage({ src: "image.png" })
 *   return status === "loaded" ? <img src="image.png" /> : <Placeholder />
 * }
 * ```
 */
export function useImage({ src, onLoad, onError }: UseImageProps) {
	const [status, setStatus] = useState<Status>('pending');

	const imageRef = useRef<HTMLImageElement | null>();

	const flush = () => {
		if (imageRef.current) {
			imageRef.current.onload = null;
			imageRef.current.onerror = null;
			imageRef.current = null;
		}
	};

	const load = useCallback(() => {
		if (!src) return;

		flush();

		const img = new Image();
		img.src = src;

		img.onload = (event) => {
			flush();
			setStatus('loaded');
			onLoad?.(event as unknown as ImageEvent);
		};
		img.onerror = (error) => {
			flush();
			setStatus('failed');
			onError?.(error as unknown as ImageEvent);
		};

		imageRef.current = img;
	}, [src, onLoad, onError]);

	useEffect(() => {
		setStatus(src ? 'loading' : 'pending');
		load();
	}, [src]);

	return status;
}
