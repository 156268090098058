import { InitiateSsoRequest, useDoInitiateSso } from '../../api/initiate-sso.api';

export const useGetSsoLoginUrl = ({ workspaceId }: InitiateSsoRequest) => {
	return useDoInitiateSso(
		{ workspaceId },
		{
			onSuccess: (data) => {
				window.location.replace(data.domain);
			},
		}
	);
};
