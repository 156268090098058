import { FC } from 'react';

import { StyledProgressBar } from './ProgressBar.styles';

export type Props = { count: number; max: number; timer?: boolean; indeterminate?: boolean };

export const ProgressBar: FC<Props> = ({ count = 0, max, timer = false, indeterminate = false }) => {
	const completionRate = (count / max) * 100;
	return (
		<StyledProgressBar
			completionRate={completionRate}
			timer={timer ? max : undefined}
			indeterminate={indeterminate}
		/>
	);
};

export default ProgressBar;
