import { FocusScope } from '@react-aria/focus';
import { ComponentPropsWithoutRef, forwardRef, ReactElement, ReactNode } from 'react';

import { usePopoverStateContext } from '../../Popover.context';
import { StyledContentWrapper } from './Content.styles';

export interface Props extends ComponentPropsWithoutRef<'div'> {
	arrow?: ReactElement;
	children: ReactNode;
	fullWidth?: boolean;
}

export const Content = forwardRef<HTMLDivElement, Props>(({ arrow, children, ...rest }, ref) => {
	const toggled = usePopoverStateContext();

	if (!toggled) return null;

	return (
		<StyledContentWrapper ref={ref} {...rest}>
			<FocusScope contain restoreFocus>
				{arrow}
				{children}
			</FocusScope>
		</StyledContentWrapper>
	);
});

Content.displayName = 'Content';
