import { Stack } from '@cheqroom/ui';
import tw, { css, styled } from 'twin.macro';

export const StyledStack = styled(Stack)<{ amountOfItems: number }>(({ amountOfItems }) => [
	tw`w-full max-h-[265px] overflow-y-auto p-4`,
	amountOfItems > 3 &&
		css`
			box-shadow: inset 0px -24px 4px -20px rgba(15, 23, 42, 0.05);
		`,
]);
