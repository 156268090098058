import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

const AUTH_URL = process.env.AUTH_URL as string;

export interface Workspace {
	id: string;
	name: string;
	strategy: AuthenticationStrategy;
	logo?: string;
}

export enum AuthenticationStrategy {
	PASSWORD = 'password',
	SSO = 'sso',
	LDAP = 'ldap',
}

export interface DiscoveryRequest {
	organisationId?: string;
}

export interface DiscoveryResponse {
	email: string;
	workspaces: Workspace[];
}

export const doDiscovery = async (
	{ organisationId }: DiscoveryRequest,
	init?: RequestInit
): Promise<DiscoveryResponse> => {
	const response = await fetch(
		new URL(!organisationId ? `${AUTH_URL}/identity` : `${AUTH_URL}/identity?organisationId=${organisationId}`),
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			credentials: 'include',
			...init,
		}
	);

	if (!response.ok) {
		throw new Error(`Unable to get the discovery: ${response.status}`);
	}

	return response.json() as Promise<DiscoveryResponse>;
};

export const useDoDiscovery = <TData>(
	{ organisationId }: DiscoveryRequest,
	options?: UseQueryOptions<DiscoveryResponse, unknown, TData>
): UseQueryResult<TData> => {
	return useQuery<DiscoveryResponse, unknown, TData, QueryKey>(
		['discovery'],
		({ signal }) => doDiscovery({ organisationId }, { signal }),
		{ ...options, staleTime: 10000 }
	);
};
