import 'twin.macro';

import { useSessionStorage } from '@cheqroom/hooks';
import { Logo } from '@cheqroom/illustrations';
import { Banner, Button, Form, Heading, Illustration, Input, LoadingSpinner, Stack, Text } from '@cheqroom/ui';
import { joiResolver } from '@hookform/resolvers/joi';
import { TFunction } from 'i18next';
import Joi from 'joi';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StyledCard } from '../../App.styles';
import { useDiscoverWorkspaces, useInitiateAuthentication, useInitiateSsoAuthentication } from './api.hooks';
import { RecentWorkspaces } from './RecentWorkspaces';

export interface EmailStepLocationState {
	state?: {
		email?: string;
		error?: string;
		success?: string;
	};
}

interface EmailStepFormData {
	email: string;
}

const emailStepValidationSchema = (t: TFunction) =>
	Joi.object<EmailStepFormData>({
		email: Joi.string()
			.trim()
			.email({ tlds: { allow: false } })
			.required()
			.messages({
				'string.base': t('validation.email.required'),
				'string.empty': t('validation.email.required'),
				'string.email': t('validation.email.format'),
			}),
	});

export const EmailStep: FC = () => {
	const { t } = useTranslation('login', {
		keyPrefix: 'email_step',
	});

	const [emailFromSession, saveEmailInSession] = useSessionStorage<string>('email');
	const { state } = useLocation() as EmailStepLocationState;

	const {
		status: initiationStatus,
		mutate: initiateAuthentication,
		error: initiateError,
	} = useInitiateAuthentication();
	const {
		status: ssoInitiationStatus,
		mutate: initiateSsoAuthentication,
		error: ssoInitiateError,
	} = useInitiateSsoAuthentication();

	const statusErrorMessage = (): string | undefined | null => {
		if (initiateError === null && ssoInitiateError === null) {
			return undefined;
		}

		if (initiateError) {
			switch (initiateError?.status) {
				case 429:
					return t('validation.generic.too_many_attempts');
				default:
					return t('validation.generic.uncaught');
			}
		}

		if (ssoInitiateError) {
			switch (ssoInitiateError?.status) {
				case 429:
					return t('validation.generic.too_many_attempts');
				default:
					return t('validation.sso.not_found');
			}
		}
	};

	const { status } = useDiscoverWorkspaces({
		onSuccess: ({ email }) => {
			saveEmailInSession(email);
		},
	});

	const { formState, handleSubmit, register } = useForm<EmailStepFormData>({
		shouldUseNativeValidation: false,
		resolver: joiResolver(emailStepValidationSchema(t)),
		defaultValues: {
			email: state?.email || emailFromSession,
		},
	});

	const handleSubmitPassword = handleSubmit(({ email }) => {
		saveEmailInSession(email);

		if (email === process.env.TEST_USER) {
			window.location.replace(process.env.TEST_LOGIN_URL as string);
		} else {
			initiateAuthentication({ email });
		}
	});

	const handleSubmitSso = handleSubmit(({ email }) => {
		saveEmailInSession(email);
		initiateSsoAuthentication({ email });
	});

	if (status === 'loading') {
		return <LoadingSpinner size="large" />;
	}

	const isInitiatingWithSso = ssoInitiationStatus === 'loading' || ssoInitiationStatus === 'success';
	const isInitiatingWithPsw = initiationStatus === 'loading';

	const isInitiating = isInitiatingWithPsw || isInitiatingWithSso;

	const bannerErrorMessage = statusErrorMessage();

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" spacing="tight" vertical>
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1">{t('header')}</Heading>

					<Text>{t('subtext')}</Text>
				</Stack>

				<RecentWorkspaces />

				<Form noValidate>
					{state?.error && <Banner variation="error">{state.error}</Banner>}

					{state?.success && <Banner variation="success">{state.success}</Banner>}

					{bannerErrorMessage && <Banner variation="error">{bannerErrorMessage}</Banner>}

					<Input
						{...register('email')}
						autoCapitalize="off"
						autoComplete={'email'}
						autoCorrect="off"
						autoFocus
						error={formState.errors.email?.message}
						id={'email'}
						label={t('email')}
						spellCheck="false"
						type={'email'}
					/>

					<input autoComplete="current-password" hidden id="password" name="password" type="password" />

					<Stack vertical spacing="tight" tw="mt-2">
						<Button
							loading={isInitiatingWithPsw}
							disabled={isInitiating}
							size="medium"
							type="submit"
							onClick={handleSubmitPassword}
						>
							{t('continue_with_password')}
						</Button>

						<Button
							variation="secondary"
							loading={isInitiatingWithSso}
							disabled={isInitiating}
							size="medium"
							type="submit"
							onClick={handleSubmitSso}
						>
							{t('continue_with_sso')}
						</Button>
					</Stack>
				</Form>
			</Stack>
		</StyledCard>
	);
};
