import 'twin.macro';

import { Logo } from '@cheqroom/illustrations';
import { Button, Heading, Illustration, Link, Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { StyledCard } from '../../App.styles';
import { Page } from '../Page/Page';

const toCookieHelpPage = 'https://help.cheqroom.com/en/';

export const CookieErrorPage: FC = () => {
	const { t } = useTranslation('error');

	const handleRefreshPageClick = () => {
		window.location.reload();
	};

	return (
		<Page>
			<Page.Content tw="flex justify-center items-center">
				<StyledCard sectioned>
					<Stack align="center" spacing="extraLoose" vertical>
						<Stack align="center" spacing="tight" vertical>
							<Illustration source={Logo} tw="mb-4" />

							<Heading tw="mt-0" element="h1">
								{t('cookie_page.heading')}
							</Heading>

							<Text align="center">{t('cookie_page.subtext')}</Text>
						</Stack>

						<Text color="subdued" size="small" align="center">
							<Trans
								i18nKey="cookie_page.need_help"
								ns="error"
								components={[
									<Link inheritSize key="0" url={toCookieHelpPage} external>
										placeholder
									</Link>,
								]}
							/>
						</Text>

						<Button onClick={handleRefreshPageClick} size="medium" type="submit" tw="w-full">
							{t('cookie_page.refresh')}
						</Button>
					</Stack>
				</StyledCard>
			</Page.Content>
		</Page>
	);
};
