import parseJwt from 'jwt-decode';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDoUpdatePassword } from '../../api/update-password.api';
import { Step } from '../../App';

interface TokenPayload {
	workspace: string;
	email: string;
	exp: number;
	sub: string;
}

const parseToken = (token: string) => {
	return parseJwt<TokenPayload>(token);
};

const isTokenExpired = (expiry: number): boolean => {
	return new Date(expiry * 1000) < new Date();
};

export const useUpdatePassword = () => {
	const navigateTo = useNavigate();
	const [params] = useSearchParams();
	const { t } = useTranslation();

	const token = params.get('token');

	useEffect(() => {
		if (!token) {
			return navigateTo(Step.ENTER_EMAIL);
		}

		const { exp, email } = parseToken(token);

		if (isTokenExpired(exp)) {
			return navigateTo(Step.FORGOT_PASSWORD, { state: { email, error: t('reset_password_step.expired') } });
		}
	}, [token, t, navigateTo]);

	return useDoUpdatePassword({
		onSuccess: () => {
			if (!token) {
				return;
			}

			const parsedToken = parseToken(token);
			const email = token ? parsedToken.email : undefined;

			const url = Step.AUTHENTICATION_STRATEGY_PASSWORD_STEP.replace(':workspaceId', parsedToken.workspace);

			navigateTo(url, {
				replace: true,
				state: {
					email,
					success: t('reset_password_step.success'),
				},
			});
		},
	});
};
