import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export interface UpdatePasswordRequest {
	token: string;
	password: string;
}

const API_URL = process.env.API_URL as string;

const doUpdatePassword = async ({ password, token }: UpdatePasswordRequest): Promise<void> => {
	const response = await fetch(`${API_URL}/updatePassword`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		body: new URLSearchParams({
			password,
			token,
		}),
	});

	if (!response.ok) {
		throw new Error(`Unable to update password: ${response.status}`);
	}
};

export const useDoUpdatePassword = (
	options?: UseMutationOptions<void, unknown, UpdatePasswordRequest, unknown>
): UseMutationResult<void, unknown, UpdatePasswordRequest, unknown> => {
	return useMutation<void, unknown, UpdatePasswordRequest, unknown>(
		['updatePassword'],
		({ password, token }) => doUpdatePassword({ password, token }),
		options
	);
};
