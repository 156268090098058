import 'twin.macro';

import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

interface Props {
	children: ReactNode;
}

interface ComponentComposition {
	Group: typeof Group;
}

const Group: FC<Props> = ({ children }) => (
	<div tw="grid grid-cols-1 sm:grid-flow-col sm:auto-cols-fr gap-4">{children}</div>
);

const Form: FC<Props & ComponentPropsWithoutRef<'form'>> & ComponentComposition = ({ children, ...rest }) => (
	<form tw="flex flex-col gap-4 w-full" {...rest}>
		{children}
	</form>
);

Form.Group = Group;

export { Form };
