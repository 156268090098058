import tw, { TwStyle } from 'twin.macro';

const colors = ['gray', 'gray-light', 'indigo', 'indigo-light', 'blue', 'yellow', 'red', 'red-light', 'green'] as const;

export type Color = (typeof colors)[number];

export const calculateColor = (input: string): Color => {
	const blacklistedColors = ['indigo-light', 'red-light', 'gray-light'];

	const value = input.split('').reduce((carry, char) => (carry += char.charCodeAt(0)), 0);
	const filteredColors = colors.filter((color) => !blacklistedColors.includes(color));

	return filteredColors[value % filteredColors.length];
};

export const getColorStyles = (color?: Color): TwStyle => {
	switch (color) {
		case 'gray':
			return tw`bg-gray-500`;
		case 'gray-light':
			return tw`bg-gray-400`;
		case 'indigo-light':
			return tw`bg-indigo-300`;
		case 'indigo':
			return tw`bg-indigo-500`;
		case 'blue':
			return tw`bg-blue-500`;
		case 'yellow':
			return tw`bg-yellow-500`;
		case 'red-light':
			return tw`bg-red-300`;
		case 'red':
			return tw`bg-red-500`;
		case 'green':
			return tw`bg-green-500`;
		default:
			return tw`bg-indigo-500`;
	}
};
