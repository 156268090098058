import { UseBaseMutationResult } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDoClearSession } from '../../api/clear-session.api';
import { AuthenticationStrategy, Workspace } from '../../api/discovery.api';
import { Step } from '../../App';
import { generateStrategyUrlForWorkspace } from '../SelectAuthenticationStrategyStep/utils';
import { SsoWorkspace, useDoDiscoverWorkspaces } from './../../api/find-workspaces.api';

type GroupedWorkspace = Omit<Workspace, 'strategy'> & { url: string };

export interface SelectWorkspacesData {
	isLoading: boolean;
	workspaces: GroupedWorkspace[];
	email?: string;
	selectWorkspace: (workspaceId: string) => void;
}

const generateLoginUrlsForWorkspaces = (workspaces: SsoWorkspace[]): GroupedWorkspace[] => {
	return workspaces.map((workspace) => ({
		id: workspace.id,
		name: workspace.name,
		url: generateStrategyUrlForWorkspace(workspace.id)(AuthenticationStrategy.SSO),
	}));
};

export const useFindWorkspaces = (): SelectWorkspacesData => {
	const [isLoading, setIsLoading] = useState(true);

	const navigateTo = useNavigate();

	const discoveryQuery = useDoDiscoverWorkspaces<{ email: string; workspaces: GroupedWorkspace[] }>(
		{},
		{
			cacheTime: 0,
			retry: false,
			select: ({ email, workspaces }) => ({
				workspaces: generateLoginUrlsForWorkspaces(workspaces),
				email,
			}),
			onSuccess: () => {
				setIsLoading(false);
			},
			onError: () => {
				navigateTo(Step.SELECT_WORKSPACE);
			},
		}
	);

	const selectWorkspace = (workspaceUrl: string) => navigateTo(workspaceUrl);

	return {
		email: discoveryQuery.data?.email,
		isLoading: isLoading,
		workspaces: discoveryQuery.data?.workspaces || [],
		selectWorkspace,
	};
};

export const useClearSession = (): UseBaseMutationResult<void, unknown, void, unknown> => {
	const navigateTo = useNavigate();

	return useDoClearSession({
		onSuccess: () => {
			navigateTo(Step.ENTER_EMAIL, { replace: true });
		},
	});
};
