import { LoadingSpinner } from '@cheqroom/ui';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetSsoLoginUrl } from './api.hooks';

export const AuthenticateWithSso: FC = () => {
	const { workspaceId } = useParams<{ workspaceId: string }>();

	useGetSsoLoginUrl({ workspaceId: workspaceId || '' });

	return <LoadingSpinner size="large" />;
};
