import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

const AUTH_URL = process.env.AUTH_URL as string;

export interface Organisation {
	id: string;
	name: string;
}

export interface DiscoverOrganisationsResponse {
	email: string;
	organisations: Organisation[];
}

export const doOrganisationsDiscovery = async (init?: RequestInit): Promise<DiscoverOrganisationsResponse> => {
	const response = await fetch(`${AUTH_URL}/organisations/discover`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		credentials: 'include',
		...init,
	});

	if (!response.ok) {
		throw new Error(`Unable to get the organisations discovery: ${response.status}`);
	}

	return response.json() as Promise<DiscoverOrganisationsResponse>;
};

export const useDoOrganisationsDiscovery = <TData>(
	options?: UseQueryOptions<DiscoverOrganisationsResponse, unknown, TData>
): UseQueryResult<TData> => {
	return useQuery<DiscoverOrganisationsResponse, unknown, TData, QueryKey>(
		['organisations-discovery'],
		({ signal }) => doOrganisationsDiscovery({ signal }),
		options
	);
};
