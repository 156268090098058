import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export interface InitiateSsoRequest {
	workspaceId: string;
}

export interface InitiateSsoResponse {
	domain: string;
}

const AUTH_URL = process.env.AUTH_URL as string;

const doInitiateSso = async ({ workspaceId }: InitiateSsoRequest, init?: RequestInit): Promise<InitiateSsoResponse> => {
	const response = await fetch(`${AUTH_URL}/initiate/${workspaceId}/sso`, {
		method: 'GET',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		...init,
	});

	if (!response.ok) {
		throw new Error(`Unable to initiate sso: ${response.status}`);
	}

	return response.json() as Promise<InitiateSsoResponse>;
};

export const useDoInitiateSso = (
	{ workspaceId }: InitiateSsoRequest,
	options?: UseQueryOptions<InitiateSsoResponse>
): UseQueryResult<InitiateSsoResponse> => {
	return useQuery<InitiateSsoResponse>(
		['initiate-sso', workspaceId],
		({ signal }) => doInitiateSso({ workspaceId }, { signal }),
		options
	);
};
