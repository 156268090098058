import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

const API_URL = process.env.API_URL as string;

export interface ResetPasswordRequest {
	email: string;
}

export class ResetPasswordError extends Error {
	public readonly status: number;

	constructor(message: string, status: number) {
		super(message);
		this.status = status;
	}
}

const doResetPassword = async ({ email }: ResetPasswordRequest): Promise<void> => {
	const response = await fetch(`${API_URL}/resetPassword`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		body: new URLSearchParams({
			email,
		}),
	});

	if (!response.ok) {
		throw new ResetPasswordError(`Unable to reset password: ${response.status}`, response.status);
	}
};

export const useDoResetPassword = (
	options?: UseMutationOptions<void, unknown, ResetPasswordRequest, unknown>
): UseMutationResult<void, ResetPasswordError, ResetPasswordRequest, unknown> => {
	return useMutation<void, ResetPasswordError, ResetPasswordRequest, unknown>(
		['resetPassword'],
		(data) => doResetPassword({ email: data.email }),
		options
	);
};
