import { FC, SVGAttributes } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Color } from '../../util/color-calculator';
import { Icon } from '../Icon/Icon';

type Size = 'medium' | 'extra-large';

interface Props {
	source: FC<SVGAttributes<SVGElement>>;
	color?: Color;
	size?: Size;
}

const getColor = (color?: Color) => {
	switch (color) {
		case 'red':
			return tw`bg-red-200 border-red-100 text-red-500`;
		case 'yellow':
			return tw`bg-yellow-200 border-yellow-100 text-yellow-700`;
		case 'green':
			return tw`bg-green-200 border-green-100 text-green-500`;
		case 'blue':
			return tw`bg-blue-200 border-blue-100 text-blue-500`;
		case 'indigo':
			return tw`bg-indigo-200 border-indigo-100 text-indigo-500`;
		default:
			return tw`bg-gray-200 border-gray-100 text-gray-500`;
	}
};

const getSize = (size: Size) => {
	switch (size) {
		case 'medium':
			return tw`w-[56px] h-[56px] border-8`;
		case 'extra-large':
			return tw`w-[112px] h-[112px] border-[16px]`;
	}
};

export const StyledColoredCircle = styled.span<{ color: Color; size: Size }>`
	${tw`inline-flex justify-center items-center border-solid rounded-full`}
	${({ color }) => getColor(color)}
	${({ size }) => getSize(size)}
`;

export const ColoredCircleIcon: FC<Props> = ({ source, color = 'indigo', size = 'medium', ...rest }) => (
	<StyledColoredCircle color={color} size={size}>
		<Icon source={source} color="inherit" size={size === 'extra-large' ? 'large' : 'medium'} {...rest} />
	</StyledColoredCircle>
);
