import 'twin.macro';

import { ArrowLeft } from '@cheqroom/icons';
import { Logo } from '@cheqroom/illustrations';
import { Avatar, Banner, Button, Heading, Illustration, LoadingSpinner, Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Step } from '../../App';
import { StyledCard } from '../../App.styles';
import { StyledStack } from '../SelectWorkspaceStep/SelectWorkspaceStep.styles';
import { useDiscoverOrganisations } from './api.hooks';

export const SelectOrganisationStep: FC = () => {
	const { t } = useTranslation();
	const navigateTo = useNavigate();

	const { isLoading, organisations, selectOrganisation } = useDiscoverOrganisations();

	if (isLoading) {
		return <LoadingSpinner size="large" />;
	}

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" vertical spacing="loose">
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1">{t('choose_organisation.header')}</Heading>

					<Banner variation="warning">{t('choose_organisation.banner')}</Banner>
				</Stack>

				<Stack tw="w-full mt-0" align="center" spacing="extraLoose" vertical>
					<StyledStack element="ul" spacing="tight" amountOfItems={organisations.length} vertical>
						{organisations.map((organisation) => (
							<li
								key={organisation.id}
								aria-label="Select organisation"
								tw="py-3 px-4 border rounded-md border-gray-200 cursor-pointer"
								onClick={() => selectOrganisation(organisation)}
							>
								<Stack justify="between" align="center">
									<Stack justify="between" align="center" spacing="loose">
										<Avatar name={organisation.name} />
										<Text weight="semibold">{organisation.name}</Text>
									</Stack>
									<Button size="small">{t('choose_organisation.join')}</Button>
								</Stack>
							</li>
						))}
					</StyledStack>

					<Stack align="center" spacing="none" vertical>
						<Button leftIcon={ArrowLeft} onClick={() => navigateTo(Step.ENTER_EMAIL)} inverted>
							{t('choose_organisation.back')}
						</Button>
						<Text tw="whitespace-pre-line mt-4" align="center">
							{t('choose_organisation.missing_organisation')}
						</Text>
					</Stack>
				</Stack>
			</Stack>
		</StyledCard>
	);
};
