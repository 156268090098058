import 'twin.macro';

import { ArrowLeft } from '@cheqroom/icons';
import { Avatar, Button, Heading, IconButton, Stack, Text, TextStyle } from '@cheqroom/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthenticationStrategy, Workspace } from '../../api/discovery.api';
import { StyledCard } from '../../App.styles';
import { generateStrategyUrlForWorkspace } from './utils';

type Props = {
	email?: string;
	workspace: Pick<Workspace, 'id' | 'name' | 'logo'>;
	onGoBackClick: () => void;
};

export const MultiStrategyLoginForm: React.FC<Props> = ({ workspace, email, onGoBackClick }) => {
	const { t } = useTranslation();
	const navigateTo = useNavigate();

	const getStrategyUrl = generateStrategyUrlForWorkspace(workspace.id || '');

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack spacing="tight" align="center" tw="w-full" justify="center">
					<Avatar url={workspace.logo} name={workspace?.name || ''} />
					<Text>
						<TextStyle variation="strong">{workspace?.name || ''}</TextStyle>
					</Text>
				</Stack>

				<Stack align="center" vertical spacing="tight" tw="w-full">
					<Heading element="h1">{t('select_auth_strategy_step.header')}</Heading>
					<Text color="subdued">{t('select_auth_strategy_step.sub_header')}</Text>
				</Stack>

				{email && (
					<Stack spacing="loose" align="center" justify="start" tw="w-full">
						<IconButton
							aria-label="Go back to email step"
							onClick={onGoBackClick}
							rounded
							source={ArrowLeft}
							variation="secondary"
						/>

						<Text>{email}</Text>
					</Stack>
				)}

				<Stack vertical spacing="tight" tw="w-full">
					<Button
						size="medium"
						type="button"
						onClick={() => navigateTo(getStrategyUrl(AuthenticationStrategy.PASSWORD))}
					>
						{t('select_auth_strategy_step.use_password_cta')}
					</Button>
					<Button
						size="medium"
						type="button"
						variation="secondary"
						onClick={() => navigateTo(getStrategyUrl(AuthenticationStrategy.SSO))}
					>
						{t('select_auth_strategy_step.use_sso_cta')}
					</Button>
					{!email && (
						<Button inverted onClick={onGoBackClick}>
							{t('general.try_different_workspace')}
						</Button>
					)}
				</Stack>
			</Stack>
		</StyledCard>
	);
};
