import { Card, Link } from '@cheqroom/ui';
import { ComponentPropsWithoutRef } from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { styled } from 'twin.macro';

export const StyledLink = tw(Link)`text-gray-200 text-sm no-underline`;

export const ScrollList = styled.ul`
	${tw`p-4 sm:pl-10 sm:pr-7 sm:-ml-10 sm:overflow-auto divide-y divide-gray-200 sm:max-h-72`}

	width: calc(100% + 5rem);
	@media (max-width: 640px) {
		width: 100%;
	}
`;

export const StyledCard = styled(Card).attrs({ spacing: 'auto' })`
	${tw`w-auto sm:w-[430px]`}
`;

export const StyledOTPInputField = styled.input<ComponentPropsWithoutRef<'input'> & { error: boolean }>`
	${tw`w-10 h-10 p-2 text-xl font-bold border border-gray-300 placeholder-gray-300 rounded text-center`}

	// Focus
	${tw`focus:border-solid focus:border-indigo-500 focus:ring-offset-0 focus:ring-indigo-100 focus:ring-4 focus:outline-none`}

	// Error
	${({ error }) => error && tw`border-red-500 bg-red-100`}

	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}
`;

export const GlobalStyle = createGlobalStyle`
	body{
		${tw`bg-gray-900 flex justify-center items-center bg-cover bg-no-repeat`}

		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1680 1144' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='.1'%3E%3Cpath d='M1658.78 531.145L1417.39 391.775L1556.76 150.378L1798.15 289.748L1658.78 531.145Z' fill='white'/%3E%3Cpath d='M141.396 937.731L-100 798.36L39.3701 556.964L280.766 696.334L141.396 937.731Z' fill='white'/%3E%3Cpath d='M215.792 259.721C277.536 152.777 369.607 66.5431 480.361 11.9253C591.115 -42.6924 715.577 -63.2413 838.01 -47.1228C960.442 -31.0043 1075.35 21.0577 1168.19 102.48C1261.03 183.902 1327.65 291.026 1359.61 410.308C1391.57 529.589 1387.44 655.669 1347.75 772.604C1308.05 889.54 1234.58 992.078 1136.61 1067.25C1038.64 1142.43 920.573 1186.86 797.349 1194.94C674.124 1203.02 551.272 1174.37 444.328 1112.63L568.01 898.404C632.585 935.687 706.765 952.983 781.17 948.106C855.576 943.229 926.864 916.399 986.02 871.006C1045.18 825.614 1089.54 763.699 1113.51 693.092C1137.48 622.484 1139.97 546.354 1120.67 474.33C1101.37 402.306 1061.15 337.622 1005.09 288.458C949.03 239.294 879.649 207.858 805.722 198.125C731.795 188.393 656.642 200.801 589.767 233.78C522.892 266.759 467.298 318.828 430.016 383.403L215.792 259.721Z' fill='white'/%3E%3C/g%3E%3C/svg%3E");

		#root{
			${tw`flex justify-between h-screen`}
		}
	}
`;
