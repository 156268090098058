import { User } from '@cheqroom/icons';
import { FC } from 'react';
import { theme } from 'twin.macro';

import { useImage } from '../../hooks/useImage';
import { calculateColor, Color } from '../../util/color-calculator';
import { Icon, IconSource } from '../Icon/Icon';
import { Image } from '../Image/Image';
import { StyledAvatar } from './Avatar.styles';

export type Size = 'extra-small' | 'small' | 'medium' | 'large';

const calculateInitials = (name: string) => {
	return name
		.split(' ')
		.map((namePart) => namePart[0])
		.join('')
		.slice(0, 2)
		.toUpperCase();
};

export interface Props {
	type?: 'circle' | 'square';
	color?: Color;
	size?: Size;
	name?: string;
	url?: string | null;
	icon?: IconSource;
}

export const Avatar: FC<Props> = ({
	color,
	name = '',
	url,
	icon = User,
	size = 'medium',
	type = 'circle',
	...rest
}: Props) => {
	const status = useImage({ src: url });
	const hasLoaded = status === 'loaded';

	/**
	 * If the avatar needs to display initials (and a color was not provided),
	 * we pick (calculate) a random color, otherwise we use the default color.
	 */
	const avatarColor = color ?? (name ? calculateColor(name) : 'indigo');

	const getIconSize = (size: Size) => {
		switch (size) {
			case 'extra-small':
				return 'extra-small';
			case 'small':
			case 'medium':
				return 'small';
			case 'large':
				return 'medium';
		}
	};

	const getContent = () => {
		if (hasLoaded) {
			return <Image alt={`avatar for ${name}`} title={`avatar for ${name}`} src={url || undefined} />;
		}

		return name ? (
			calculateInitials(name)
		) : (
			// TODO: Get consistent color pattern for icons (square seems to have a different rule according to the designs)
			<Icon source={icon} color={type === 'square' ? theme`colors.gray.500` : 'white'} size={getIconSize(size)} />
		);
	};

	return (
		<StyledAvatar
			{...rest}
			type={type}
			color={avatarColor}
			size={size}
			url={hasLoaded ? url : undefined}
			title={name}
		>
			{getContent()}
		</StyledAvatar>
	);
};
