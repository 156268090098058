import 'twin.macro';

import { ArrowLeft } from '@cheqroom/icons';
import { Logo } from '@cheqroom/illustrations';
import { Button, Heading, IconButton, Illustration, Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Step } from '../../App';
import { StyledCard } from '../../App.styles';

export interface PasswordOrSSOStepLocationState {
	state?: {
		email?: string;
		redirect?: string;
	};
}

export const PasswordOrSSOStep: FC = () => {
	const navigateTo = useNavigate();
	const { state } = useLocation() as PasswordOrSSOStepLocationState;
	const { email, redirect } = state || {};
	const { t } = useTranslation();

	if (!email || !redirect) {
		return <Navigate to={Step.ENTER_EMAIL} />;
	}

	const handleRedirect = () => window.location.replace(redirect);

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" vertical spacing="loose">
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1">{t('password_or_sso_step.header')}</Heading>

					<Text>{t('password_or_sso_step.subtext')}</Text>
				</Stack>

				<Stack vertical spacing="loose">
					<Stack spacing="loose" align="center">
						<IconButton
							aria-label={t('password_or_sso_step.back')}
							onClick={() => navigateTo(Step.ENTER_EMAIL, { state: { email } })}
							rounded
							source={ArrowLeft}
							title={t('password_or_sso_step.back')}
							variation="secondary"
						/>

						<Text>{email}</Text>
					</Stack>

					<Button size="medium" type="button" onClick={handleRedirect}>
						{t('password_or_sso_step.use_sso')}
					</Button>

					<Button
						size="medium"
						type="button"
						onClick={() => navigateTo(Step.AUTHENTICATION_STRATEGY_PASSWORD_STEP)}
						inverted
					>
						{t('password_or_sso_step.use_password')}
					</Button>
				</Stack>
			</Stack>
		</StyledCard>
	);
};
