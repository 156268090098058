import { UseMutationOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { ExchangeCodeRequest, useDoTokenExchange } from '../../api/exchange-code.api';
import { useDoInitiate } from '../../api/initiate.api';
import { Step } from '../../App';

export const useExchangeCode = (options?: UseMutationOptions<void, unknown, ExchangeCodeRequest, unknown>) => {
	const navigateTo = useNavigate();

	return useDoTokenExchange({
		onSuccess: () => {
			navigateTo(Step.SELECT_WORKSPACE);
		},
		...options,
	});
};

export const useInitiateAuthentication = useDoInitiate;
