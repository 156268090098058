import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDoClearSession } from '../../api/clear-session.api';
import { Step } from '../../App';

export const useClearSession = () => {
	const navigateTo = useNavigate();

	const { mutate } = useDoClearSession({
		onSettled: () => {
			navigateTo(Step.ENTER_EMAIL, { replace: true });
		},
	});

	// Automatically trigger clear session API when visiting the route
	useEffect(() => {
		mutate();
	}, [mutate]);
};
