import tw, { css, styled, theme } from 'twin.macro';

import { Direction, IconAlignment, Props, Variation } from './Banner';

type StyledBannerProps = Pick<Props, 'squared' | 'direction' | 'variation' | 'alignIcon'>;

export const getVariation = (variation?: Variation) => {
	switch (variation) {
		case 'error':
			return tw`bg-red-50 border border-red-300 text-red-900`;
		case 'warning':
			return tw`bg-yellow-50 border border-yellow-300 text-yellow-900`;
		case 'success':
			return tw`bg-green-50 border border-green-300 text-green-900`;
		case 'info':
			return tw`bg-blue-50 border border-blue-300 text-blue-900`;
		default:
			return tw`bg-white border border-gray-200 text-gray-700`;
	}
};

export const getIconColor = (variation?: Variation): string => {
	switch (variation) {
		case 'error':
			return theme`colors.red.500`;
		case 'warning':
			return theme`colors.yellow.500`;
		case 'success':
			return theme`colors.green.500`;
		case 'info':
			return theme`colors.blue.500`;
		default:
			return theme`colors.indigo.500`;
	}
};

const getDirection = (direction?: Direction) => {
	switch (direction) {
		case 'auto':
			return css`
				${tw`flex-col sm:flex-row`}

				svg {
					${tw`mt-0 sm:mt-0`}
				}
			`;
		case 'row':
			return css`
				${tw`flex-row`}

				svg {
					${tw`mr-2`}
				}
			`;
		case 'column':
			return css`
				${tw`flex-col text-center`}

				svg {
					${tw`mb-2`}
				}

				p {
					${tw`text-center`}
				}
			`;
	}
};

const getIconAlignment = (alignIcon?: IconAlignment) => {
	switch (alignIcon) {
		case 'top':
			return css`
				svg {
					${tw`mt-0 sm:mt-0 self-start`}
				}
			`;
		case 'center':
			return css`
				svg {
					${tw`mt-0 sm:mt-0 self-center`}
				}
			`;
		case 'bottom':
			return css`
				svg {
					${tw`mt-0 sm:mt-0 self-end`}
				}
			`;
	}
};

export const StyledBanner = styled.div<StyledBannerProps>(({ squared, direction, variation, alignIcon }) => [
	tw`relative py-4 px-4 flex gap-2 items-center font-normal overflow-hidden`,
	squared ? null : tw`rounded-md`,
	getDirection(direction),
	getVariation(variation),
	getIconAlignment(alignIcon),
]);
