import { Children, cloneElement, ForwardedRef, forwardRef, ReactElement } from 'react';

import { usePopoverDispatchContext } from '../../Popover.context';

type ChildrenWithRenderProps = (props: { onToggle: () => void; ref: ForwardedRef<HTMLElement> }) => ReactElement;

type AutoProps = {
	manual?: false;
	children: ReactElement;
};

type ManualProps = {
	manual?: true;
	children: ChildrenWithRenderProps;
};

type Props = AutoProps | ManualProps;

export const Trigger = forwardRef<HTMLElement, Props>(({ children, manual }, ref) => {
	const { onToggle } = usePopoverDispatchContext();

	if (manual) {
		return children({
			onToggle,
			ref,
		});
	}

	const child = Children.only(children as ReactElement);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Enter' || event.key === ' ') {
			onToggle();
		}
	};

	return cloneElement(child, { onClick: onToggle, onKeyDown: handleKeyDown, ref });
});

Trigger.displayName = 'Trigger';
