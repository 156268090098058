import 'twin.macro';

import { Logo } from '@cheqroom/illustrations';
import { Banner, Button, Heading, Illustration, LoadingSpinner, Stack, Text, TextStyle } from '@cheqroom/ui';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StyledCard } from '../../App.styles';
import { useClearSession, useLoadWorkspaces } from './api.hooks';
import { StyledStack } from './SelectWorkspaceStep.styles';
import { Workspace } from './Workspace';

interface SelectWorkspaceStepState {
	state?: {
		organisationId?: string;
		error?: string;
	};
}

export const SelectWorkspaceStep: FC = () => {
	const { t } = useTranslation();
	const { state: locationState } = useLocation() as SelectWorkspaceStepState;

	const { workspaces, email, isLoading, selectWorkspace } = useLoadWorkspaces(locationState?.organisationId);
	const clearSession = useClearSession();

	const handleChangeEmail = () => {
		clearSession.mutate();
	};

	if (isLoading) {
		return <LoadingSpinner size="large" />;
	}

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" spacing="tight" vertical>
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1">{t('choose_workspace.header')}</Heading>

					<Text align="center">{t('choose_workspace.subheading')}</Text>
				</Stack>

				<hr tw="bg-gray-200" />

				{locationState?.error && <Banner variation="error">{locationState.error}</Banner>}

				{workspaces.length <= 0 && (
					<Banner variation="warning">{t('choose_workspace.email_not_in_workspaces')}</Banner>
				)}

				{workspaces.length > 0 && (
					<>
						<Text tw="whitespace-pre-line" align="center">
							<Trans
								i18nKey="choose_workspace.workspaces_title"
								values={{ email }}
								components={[
									<TextStyle key="0" variation="strong">
										placeholder
									</TextStyle>,
								]}
							/>
						</Text>

						<StyledStack element="ul" spacing="tight" amountOfItems={workspaces.length} vertical>
							{workspaces.map((workspace) => (
								<Workspace
									key={workspace.id}
									workspace={workspace}
									ariaLabel={t('choose_workspace.aria_label', { name: workspace.name })}
									onClick={(event) => {
										event.preventDefault();
										selectWorkspace(workspace.url);
									}}
									to={workspace.url}
								/>
							))}
						</StyledStack>
					</>
				)}

				<Stack align="center" spacing="extraTight" vertical>
					<Text tw="whitespace-pre-line" align="center">
						{t('choose_workspace.missing_workspace')}
					</Text>
					<Button size="medium" type="button" inverted onClick={handleChangeEmail}>
						{t('choose_workspace.try_different_email')}
					</Button>
				</Stack>
			</Stack>
		</StyledCard>
	);
};
