import { useSessionStorage } from '@cheqroom/hooks';
import { UseBaseMutationResult } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useDoClearSession } from '../../api/clear-session.api';
import { useDoDiscovery, Workspace } from '../../api/discovery.api';
import { useDoTokenExchange } from '../../api/exchange-code.api';
import { Step } from '../../App';
import { generateStrategyUrlForWorkspace } from '../SelectAuthenticationStrategyStep/utils';

type GroupedWorkspace = Omit<Workspace, 'strategy'> & { url: string };

const generateLoginStrategiesForWorkspaces = (workspaces: Workspace[]): GroupedWorkspace[] => {
	const uniqueWorkspacesWithCorrectRedirectUrl = workspaces.reduce((workspaces, workspace) => {
		const getStrategyLocation = generateStrategyUrlForWorkspace(workspace.id);

		const workspaceAlreadyExists = workspaces.has(workspace.id);
		const url = workspaceAlreadyExists ? `/${workspace.id}/login` : getStrategyLocation(workspace.strategy);

		return workspaces.set(workspace.id, { ...workspace, url });
	}, new Map<string, GroupedWorkspace>());

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	return [...uniqueWorkspacesWithCorrectRedirectUrl].map(([_, value]) => value);
};

export interface SelectWorkspacesData {
	isLoading: boolean;
	workspaces: GroupedWorkspace[];
	email?: string;
	selectWorkspace: (workspaceUrl: string) => void;
}

interface SelectWorkspaceStepState {
	state: {
		error?: string;
	};
}

export const useLoadWorkspaces = (organisationId?: string): SelectWorkspacesData => {
	const [isLoading, setIsLoading] = useState(true);
	const { t } = useTranslation();

	const [params] = useSearchParams();
	const token = params.get('token');

	const navigateTo = useNavigate();

	const { state: locationState } = useLocation() as SelectWorkspaceStepState;
	const [organisationIdFromSession, setOrganisationInSession] = useSessionStorage<string | undefined>('organisation');

	const clearSessionOnTokenExchangeFailure = useDoClearSession({
		onSettled: () => {
			navigateTo(Step.ENTER_EMAIL, { replace: true, state: { error: t('select_workspace_step.invalid_url') } });
		},
	});

	const discoveryQuery = useDoDiscovery<{ email: string; workspaces: GroupedWorkspace[] }>(
		{ organisationId: organisationId ?? organisationIdFromSession },
		{
			cacheTime: 0,
			retry: false,
			enabled: false,
			select: (data) => ({
				email: data.email,
				workspaces: generateLoginStrategiesForWorkspaces(data.workspaces),
			}),
			onSuccess: (data) => {
				if (data.workspaces.length === 1) {
					// If there is only 1 workspace we don't want to automatically select it
					// if there's an error (f.e during SSO login), otherwise he can get in an endless loop
					if (!locationState?.error) {
						selectWorkspace(data.workspaces[0].url);
					}
				}
				setIsLoading(false);
			},
			onError: () => {
				if (!locationState?.error) {
					navigateTo(Step.SELECT_ORGANISATION);
				} else {
					// If SSO was initiated from email step and there was an error,
					// we want to show this error on the email step
					navigateTo(Step.ENTER_EMAIL, { replace: true, state: locationState });
				}
			},
		}
	);

	const selectWorkspace = (workspaceUrl: string) => {
		setOrganisationInSession(undefined);
		return navigateTo(workspaceUrl);
	};

	const doTokenExchange = useDoTokenExchange({
		onSuccess: () => {
			return discoveryQuery.refetch();
		},
		onError: () => {
			clearSessionOnTokenExchangeFailure.mutate();
		},
	});

	useEffect(() => {
		if (token) {
			doTokenExchange.mutate({ token });
		} else {
			void discoveryQuery.refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	return {
		email: discoveryQuery.data?.email,
		isLoading: isLoading,
		workspaces: discoveryQuery.data?.workspaces || [],
		selectWorkspace,
	};
};

export const useClearSession = (): UseBaseMutationResult<void, unknown, void, unknown> => {
	const navigateTo = useNavigate();

	return useDoClearSession({
		onSettled: () => {
			navigateTo(Step.ENTER_EMAIL, { replace: true });
		},
	});
};
