import { UseMutationResult } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useDoClearSession } from '../../api/clear-session.api';
import { DiscoveryResponse, useDoDiscovery, Workspace } from '../../api/discovery.api';
import { Step } from '../../App';
import { generateStrategyUrlForWorkspace } from './utils';

export const useDiscovery = () => {
	const { workspaceId } = useParams<{ workspaceId: string }>();
	const navigateTo = useNavigate();
	const getStrategyLocation = generateStrategyUrlForWorkspace(workspaceId || '');

	const queryData = useDoDiscovery<DiscoveryResponse>(
		{},
		{
			cacheTime: 0,
			onSuccess: (response) => {
				const workspaces = response.workspaces.filter(({ id }) => workspaceId === id);

				if (workspaces.length === 0) {
					return navigateTo(Step.SELECT_WORKSPACE, { replace: true });
				}

				if (workspaces.length === 1) {
					return navigateTo(getStrategyLocation(workspaces[0].strategy));
				}
			},
		}
	);

	const strategies = queryData.data?.workspaces.filter(({ id }) => workspaceId === id);

	const data = {
		email: queryData.data?.email,
		workspace: {
			...strategies?.[0],
		} as Workspace,
		workspaces: queryData.data?.workspaces,
	};

	return { ...queryData, data, getStrategyLocation };
};

export const useClearSession = (): UseMutationResult<void, unknown, void, unknown> => {
	const navigateTo = useNavigate();

	return useDoClearSession({
		onSettled: () => {
			navigateTo(Step.ENTER_EMAIL, { replace: true });
		},
	});
};
