import { ComponentPropsWithoutRef, FC } from 'react';

import { StyledLink } from './Link.styles';

export interface Props extends Omit<ComponentPropsWithoutRef<'a'>, 'href'> {
	external?: boolean;
	url?: string;
	inheritSize?: boolean;
	unstyled?: boolean;
}

export const Link: FC<Props> = ({ children, external, url, unstyled = false, ...rest }) => {
	const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : null;
	return (
		<StyledLink {...rest} href={url} {...externalProps} unstyled={unstyled}>
			{children}
		</StyledLink>
	);
};
