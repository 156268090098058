import { useSessionStorage } from '@cheqroom/hooks';
import { QueryStatus, UseQueryOptions } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PythonRequestError, useDoAuthenticate } from '../../api/authenticate.api';
import { useDoClearSession } from '../../api/clear-session.api';
import { DiscoveryResponse, useDoDiscovery, Workspace } from '../../api/discovery.api';
import { useRecentWorkspaces } from '../../api/find-workspaces.api';
import { useDoGetTokensMutation } from '../../api/get-tokens.api';
import { Step } from '../../App';

const APP_URL = process.env.APP_URL as string;

type LoadDiscoveryResult = {
	status: QueryStatus;
	email?: string;
	workspace?: Workspace;
	onGoBack: () => void;
};

export const useLoadDiscovery = (queryOptions?: UseQueryOptions<DiscoveryResponse>): LoadDiscoveryResult => {
	const navigateTo = useNavigate();

	const { mutate: clearSession } = useDoClearSession({
		onSettled: () => navigateTo(Step.ENTER_EMAIL, { replace: true }),
	});

	const { workspaceId } = useParams<{ workspaceId: string }>();

	const queryData = useDoDiscovery<DiscoveryResponse>(
		{},
		{
			cacheTime: 0,
			retry: false,
			...queryOptions,
		}
	);

	const onGoBack = () => {
		const workspaces = queryData.data?.workspaces || [];

		const strategiesForWorkspace = workspaces.filter(({ id }) => id === workspaceId);
		if (strategiesForWorkspace.length === 2) {
			return navigateTo(Step.SELECT_AUTHENTICATION_MULTI_STRATEGY_STEP);
		}

		if (workspaces.length > 1) {
			return navigateTo(Step.SELECT_WORKSPACE);
		}

		clearSession();
		return navigateTo(Step.ENTER_EMAIL);
	};

	return {
		status: queryData.status,
		email: queryData.data?.email,
		workspace: queryData.data?.workspaces.find(({ id }) => id === workspaceId),
		onGoBack,
	};
};

export const useAuthenticate = () => {
	const [statusForLoadingAfterRedirect, setStatusForLoadingAfterRedirect] = useState<QueryStatus>();

	const [isMobileApp] = useSessionStorage<boolean>('mobile');
	const { add: addRecentWorkspace } = useRecentWorkspaces();

	const [redirect_uri, _, clearStorage] = useSessionStorage<string>('CHEQROOM_REDIRECT_KEY');

	const state = useDoAuthenticate({
		onMutate: () => setStatusForLoadingAfterRedirect('loading'),
		onError: () => setStatusForLoadingAfterRedirect('error'),
		onSuccess: (response) => {
			const decodedToken = jwtDecode<{ workspace: string }>(response.access_token);

			addRecentWorkspace(decodedToken.workspace);

			if (isMobileApp) {
				window.location.replace(
					`/mobile.html?access_token=${response.access_token}&session_token=${response.refresh_token}`
				);
			} else {
				const url = new URL(`${APP_URL}/${decodedToken.workspace}`);
				url.searchParams.append('access_token', response.access_token);
				url.searchParams.append('session_token', response.refresh_token);

				if (redirect_uri) {
					url.searchParams.append('redirect_uri', redirect_uri);
				}

				clearStorage();
				window.location.replace(url);
			}
		},
	});

	return {
		...state,
		isLoading: statusForLoadingAfterRedirect === 'loading',
		status: statusForLoadingAfterRedirect,
	};
};

export const useImprovedAuthenticate = () => {
	const [statusForLoadingAfterRedirect, setStatusForLoadingAfterRedirect] = useState<QueryStatus>();

	const [isMobileApp] = useSessionStorage<boolean>('mobile');
	const { add: addRecentWorkspace } = useRecentWorkspaces();

	const [redirect_uri, _, clearStorage] = useSessionStorage<string>('CHEQROOM_REDIRECT_KEY');

	const state = useDoGetTokensMutation({
		onMutate: () => setStatusForLoadingAfterRedirect('loading'),
		onError: () => setStatusForLoadingAfterRedirect('error'),
		onSuccess: (response) => {
			const decodedToken = jwtDecode<{ workspace: string }>(response.access_token);

			addRecentWorkspace(decodedToken.workspace);

			if (isMobileApp) {
				window.location.replace(
					`/mobile.html?access_token=${response.access_token}&session_token=${response.refresh_token}`
				);
			} else {
				const url = new URL(`${APP_URL}/${decodedToken.workspace}`);
				url.searchParams.append('access_token', response.access_token);
				url.searchParams.append('session_token', response.refresh_token);

				if (redirect_uri) {
					url.searchParams.append('redirect_uri', redirect_uri);
				}

				clearStorage();
				window.location.replace(url);
			}
		},
	});

	return {
		...state,
		isLoading: statusForLoadingAfterRedirect === 'loading',
		status: statusForLoadingAfterRedirect,
	};
};

export const formatPythonError = (t: TFunction, error?: PythonRequestError) => {
	const description = error?.description?.toLowerCase();
	if (!description) return t('password_step.errors.uncaught');

	if (description.includes('invalid user')) {
		return t('password_step.errors.invalid');
	}
	if (description.includes('subscription expired')) {
		return t('password_step.errors.expired');
	}
	if (description.includes('login is inactive')) {
		return t('password_step.errors.inactive');
	}
	if (description.includes('subscription cancelled')) {
		return t('password_step.errors.cancelled');
	}
	if (description.includes('login with sso')) {
		return t('password_step.errors.sso');
	}
	if (description.includes('too many requests') || error?.status == 429) {
		return t('password_step.errors.too_many_attempts');
	}
};
