import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

const AUTH_URL = process.env.AUTH_URL as string;

export interface CodeRequest {
	code: string;
	email: string;
	token?: undefined;
}

export interface TokenRequest {
	code?: undefined;
	email?: undefined;
	token: string;
}

export type ExchangeCodeRequest = CodeRequest | TokenRequest;

export const doTokenExchange = async ({ code, email, token }: ExchangeCodeRequest): Promise<void> => {
	const response = await fetch(`${AUTH_URL}/exchange-code`, {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ code, email, token }),
	});

	if (!response.ok) {
		throw new Error(`Unable to get the discovery: ${response.status}`);
	}
};

export const useDoTokenExchange = (
	options?: UseMutationOptions<void, unknown, ExchangeCodeRequest, unknown>
): UseMutationResult<void, unknown, ExchangeCodeRequest, unknown> => {
	return useMutation<void, unknown, ExchangeCodeRequest, unknown>(
		['token-exchange'],
		(data) => doTokenExchange(data),
		options
	);
};
