import { FC, ReactNode } from 'react';

import { StyledContainer, StyledContent } from './Content.styles';

interface Props {
	children?: ReactNode;
}

const Content: FC<Props> = ({ children, ...props }) => {
	return (
		<StyledContainer {...props}>
			<StyledContent>{children}</StyledContent>
		</StyledContainer>
	);
};

export default Content;
