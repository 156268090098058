import { FC } from 'react';

export const Logo: FC = (props) => (
	<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M30.7354 3.33423C27.4862 1.16032 23.6661 -1.71039e-07 19.7583 0V7.83811C22.1179 7.83811 24.4245 8.53873 26.3865 9.85138C28.3484 11.164 29.8776 13.0297 30.7806 15.2126C31.6836 17.3954 31.9198 19.7974 31.4595 22.1147C30.9991 24.432 29.8629 26.5606 28.1944 28.2312C26.5259 29.9019 24.4001 31.0397 22.0858 31.5006C19.7715 31.9616 17.3727 31.725 15.1927 30.8208C13.0127 29.9166 11.1494 28.3855 9.83852 26.421C8.52758 24.4565 7.82788 22.1468 7.82788 19.7841H0C0 23.6971 1.1588 27.5221 3.32987 30.7756C5.50094 34.0291 8.58677 36.5649 12.1971 38.0623C15.8075 39.5597 19.7802 39.9515 23.613 39.1881C27.4457 38.4247 30.9663 36.5405 33.7295 33.7736C36.4928 31.0068 38.3746 27.4816 39.137 23.6438C39.8993 19.8061 39.508 15.8281 38.0126 12.2131C36.5171 8.59799 33.9847 5.50813 30.7354 3.33423Z"
			fill="#0F172A"
		/>
		<path
			d="M0 0.000225779V8.80023L8.78851 8.80023V17.6L17.577 17.6L17.577 8.80001L8.78851 8.80023L8.78851 0.000224627L0 0.000225779Z"
			fill="#0F172A"
		/>
		<path d="M35.154 44V35.2H43.9426L43.9426 44L35.154 44Z" fill="#0F172A" />
	</svg>
);
