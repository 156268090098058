import 'twin.macro';

import { LockClosed } from '@cheqroom/icons';
import { Logo } from '@cheqroom/illustrations';
import { Banner, Button, Form, Heading, Icon, Illustration, Input, Stack } from '@cheqroom/ui';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { StyledCard } from '../../App.styles';
import { useUpdatePassword } from './api.hooks';

interface ResetPasswordFormData {
	password: string;
	confirmPassword: string;
}

export const ResetPasswordStep: FC = () => {
	const { register, watch, formState, handleSubmit } = useForm<ResetPasswordFormData>({
		mode: 'onBlur',
	});
	const password = watch('password', '');
	const { t } = useTranslation();

	const [params] = useSearchParams();

	const { status, mutate: changePassword } = useUpdatePassword();

	const onSubmit = handleSubmit((data) => {
		const token = params.get('token');
		if (!token) return;

		changePassword({ token, password: data.password });
	});

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" vertical spacing="loose">
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1">{t('reset_password_step.header')}</Heading>
				</Stack>

				<Form onSubmit={onSubmit}>
					{status === 'error' && <Banner variation="error">{t('reset_password_step.reset_failed')}</Banner>}

					<Input
						{...register('password', {
							required: t('reset_password_step.validation.password.required'),
							minLength: {
								value: 10,
								message: t('reset_password_step.validation.password.min_length', { count: 10 }),
							},
							pattern: {
								value: /\d+/,
								message: t('reset_password_step.validation.password.pattern', { count: 1 }),
							},
						})}
						label={t('reset_password_step.new_password')}
						type="password"
						autoFocus
						prefix={<Icon source={LockClosed} tw="text-gray-400" />}
						error={formState.errors.password?.message}
					/>

					<Input
						{...register('confirmPassword', {
							required: t('reset_password_step.validation.password.confirm'),
							validate: (value) =>
								value === password || t('reset_password_step.validation.password.mismatch'),
						})}
						label={t('reset_password_step.reset_password')}
						type="password"
						prefix={<Icon source={LockClosed} tw="text-gray-400" />}
						error={formState.errors.confirmPassword?.message}
					/>

					<Button size="medium" type="submit" loading={status === 'loading'}>
						{t('reset_password_step.change_password')}
					</Button>
				</Form>
			</Stack>
		</StyledCard>
	);
};
