import 'twin.macro';

import { ArrowLeft, LockClosed } from '@cheqroom/icons';
import { Avatar, Banner, Button, Form, Heading, Icon, IconButton, Input, Stack, Text, TextStyle } from '@cheqroom/ui';
import { joiResolver } from '@hookform/resolvers/joi';
import { useFeature } from '@optimizely/react-sdk';
import { TFunction } from 'i18next';
import Joi from 'joi';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Workspace } from '../../api/discovery.api';
import { Step } from '../../App';
import { StyledCard } from '../../App.styles';
import { formatPythonError, useAuthenticate, useImprovedAuthenticate } from './api.hooks';

interface PasswordStepLocationState {
	state?: {
		email?: string;
		success?: string;
	};
}

interface PasswordStepFormData {
	password: string;
	email: string;
}

const passwordStepValidationSchema = (t: TFunction) =>
	Joi.object<PasswordStepFormData>({
		email: Joi.string()
			.trim()
			.email({ tlds: { allow: false } })
			.required()
			.messages({
				'string.base': t('password_step.validation.email.required'),
				'string.empty': t('password_step.validation.email.required'),
				'string.email': t('password_step.validation.email.format'),
			}),
		password: Joi.string()
			.required()
			.messages({
				'string.base': t('password_step.validation.password.required'),
				'string.empty': t('password_step.validation.password.required'),
			}),
	});

type Props = {
	email?: string;
	workspace?: Pick<Workspace, 'name' | 'logo'>;
	onGoBackClick: () => void;
	canChangeEmail: boolean;
};

const PasswordLoginForm: FC<Props> = ({ email, workspace, onGoBackClick, canChangeEmail }) => {
	const [useImprovedTokens] = useFeature('improved_token_pair');

	const { t } = useTranslation('login');

	const { workspaceId } = useParams<{ workspaceId: string }>();
	const { state } = useLocation() as PasswordStepLocationState;
	const navigateTo = useNavigate();

	const { register, formState, handleSubmit, watch, trigger } = useForm<PasswordStepFormData>({
		resolver: joiResolver(passwordStepValidationSchema(t)),
		defaultValues: {
			email: state?.email || email,
		},
	});
	const emailFromForm = watch('email');

	const authenticate = useAuthenticate();
	const improvedAuthenticate = useImprovedAuthenticate();

	const handleSubmitForm = handleSubmit((data) => {
		if (useImprovedTokens) {
			improvedAuthenticate.mutate({
				password: data.password,
				email: data.email,
				client_id: workspaceId || '',
				client_secret: 'web',
				grant_type: 'password',
			});
		} else {
			authenticate.mutate({ password: data.password, username: data.email, workspaceId: workspaceId || '' });
		}
	});

	const isSubmitting = authenticate.status === 'loading' || improvedAuthenticate.status === 'loading';
	const hasError = authenticate.status === 'error' || improvedAuthenticate.status === 'error';

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				{workspace && (
					<Stack spacing="tight" align="center" tw="w-full" justify="center">
						<Avatar url={workspace.logo} name={workspace.name} />
						<Text>
							<TextStyle variation="strong">{workspace.name}</TextStyle>
						</Text>
					</Stack>
				)}

				<Stack align="center" vertical spacing="tight" tw="w-full">
					<Heading element="h1">{t('select_auth_strategy_step.header')}</Heading>
					<Text color="subdued">{t('select_auth_strategy_step.sub_header')}</Text>
				</Stack>

				{state?.success && <Banner variation="success">{state.success}</Banner>}
				{hasError && <Banner variation="error">{formatPythonError(t, authenticate.error ?? undefined)}</Banner>}

				{!canChangeEmail && (
					<Stack spacing="loose" align="center" justify="start" tw="w-full">
						<IconButton
							aria-label={t('password_step.back')}
							onClick={onGoBackClick}
							rounded
							source={ArrowLeft}
							title={t('password_step.back')}
							variation="secondary"
						/>

						<Text>{email}</Text>
					</Stack>
				)}

				<Form onSubmit={handleSubmitForm}>
					{canChangeEmail && (
						<Input
							{...register('email')}
							autoCapitalize="off"
							autoComplete="email"
							autoCorrect="off"
							spellCheck="false"
							autoFocus={!email}
							error={formState.errors.email?.message}
							readOnly={isSubmitting}
							type="email"
							tabIndex={1}
							label={t('password_step.email')}
						/>
					)}

					<Input
						{...register('password')}
						autoComplete="current-password"
						autoFocus={!!email}
						error={formState.errors.password?.message}
						readOnly={isSubmitting}
						type="password"
						tabIndex={1}
						label={
							<Stack justify="between">
								<TextStyle variation="normal">{t('password_step.password')}</TextStyle>
								<Link
									to={Step.FORGOT_PASSWORD}
									state={{ email: emailFromForm }}
									onClick={async (event) => {
										event.preventDefault();
										const valid = await trigger('email');
										if (valid) {
											navigateTo(Step.FORGOT_PASSWORD, { state: { email: emailFromForm } });
										}
									}}
									tabIndex={0}
								>
									<TextStyle variation="normal" tw="text-indigo-500">
										{t('password_step.forgot_password')}
									</TextStyle>
								</Link>
							</Stack>
						}
						prefix={<Icon source={LockClosed} tw="text-gray-400" />}
					/>

					<Stack vertical spacing="tight">
						<Button disabled={isSubmitting} loading={isSubmitting} size="medium" type="submit">
							{t('password_step.login')}
						</Button>
						{canChangeEmail && (
							<Button inverted onClick={onGoBackClick}>
								{t('general.try_different_workspace')}
							</Button>
						)}
					</Stack>
				</Form>
			</Stack>
		</StyledCard>
	);
};

export default PasswordLoginForm;
