import { LoadingSpinner } from '@cheqroom/ui';
import { FC } from 'react';

import { useClearSession } from './api.hooks';

export const LogoutStep: FC = () => {
	useClearSession();

	return <LoadingSpinner size="large" />;
};
