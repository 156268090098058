import 'twin.macro';

import { ArrowLeft, ArrowRight, Search } from '@cheqroom/icons';
import { Logo } from '@cheqroom/illustrations';
import {
	Avatar,
	Banner,
	Button,
	ColoredCircleIcon,
	Heading,
	Icon,
	Illustration,
	Link,
	LoadingSpinner,
	Stack,
	Text,
	TextStyle,
} from '@cheqroom/ui';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { StyledCard } from '../../App.styles';
import { StyledStack } from '../SelectWorkspaceStep/SelectWorkspaceStep.styles';
import { useClearSession, useFindWorkspaces } from './api.hooks';

interface SelectWorkspaceStepState {
	state?: {
		organisationId?: string;
		error?: string;
	};
}

export const FindWorkspaceStep: FC = () => {
	const { t } = useTranslation('login', {
		keyPrefix: 'choose_workspace',
	});

	const { state: locationState } = useLocation() as SelectWorkspaceStepState;

	const { workspaces, email, isLoading, selectWorkspace } = useFindWorkspaces();

	const clearSession = useClearSession();

	const handleChangeEmail = () => {
		clearSession.mutate();
	};

	const handleReturnToApplication = () => {
		window.location.assign(process.env.APP_URL as string);
	};

	if (isLoading) {
		return <LoadingSpinner size="large" />;
	}

	if (workspaces.length === 0) {
		return (
			<StyledCard sectioned>
				<Stack align="center" vertical>
					<ColoredCircleIcon source={Search} />
					<Stack tw="mt-4 mb-6" align="center" spacing="tight" vertical>
						<Text weight="semibold" align="center">
							{t('no_workspaces.title')}
						</Text>
						<Stack spacing="extraTight" vertical>
							<Text align="center">{t('no_workspaces.body')}</Text>
							<Text align="center">
								<Link tw="cursor-pointer" key="0" target="_blank" onClick={handleChangeEmail}>
									{t('no_workspaces.link')}
								</Link>
							</Text>
						</Stack>
					</Stack>
					<Button
						size="medium"
						variation="secondary"
						leftIcon={ArrowLeft}
						onClick={handleReturnToApplication}
					>
						{t('no_workspaces.return_to_application')}
					</Button>
				</Stack>
			</StyledCard>
		);
	}

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" vertical spacing="tight">
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1">{t('header')}</Heading>

					<Text align="center">{t('subheading')}</Text>
				</Stack>

				<Stack tw="w-full pt-6 border-t border-gray-200" align="center" spacing="loose" vertical>
					{locationState?.error && <Banner variation="error">{locationState.error}</Banner>}

					<Text tw="whitespace-pre-line" align="center">
						<Trans
							i18nKey="choose_workspace.workspaces_title"
							values={{ email }}
							components={[
								<TextStyle key="0" variation="strong">
									placeholder
								</TextStyle>,
							]}
						/>
					</Text>

					<StyledStack element="ul" spacing="tight" amountOfItems={workspaces.length} vertical>
						{workspaces.map((workspace) => (
							<li
								key={workspace.id}
								aria-label="Select workspace"
								tw="py-3 px-4 border rounded-md border-gray-200 cursor-pointer"
								onClick={() => selectWorkspace(workspace.url)}
							>
								<Stack justify="between" align="center">
									<Stack justify="between" align="center" spacing="loose">
										<Avatar url={workspace.logo} name={workspace.name} />
										<Text weight="semibold">{workspace.name}</Text>
									</Stack>

									<Icon source={ArrowRight} tw="text-gray-500" />
								</Stack>
							</li>
						))}
					</StyledStack>

					<Stack align="center" spacing="extraTight" vertical>
						<Text tw="whitespace-pre-line mt-6" align="center">
							{t('missing_workspace')}
						</Text>
						<Button size="medium" type="button" inverted onClick={handleChangeEmail}>
							{t('try_different_email')}
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</StyledCard>
	);
};
