import { css } from 'styled-components';
import tw, { styled } from 'twin.macro';

import { Props } from './Popover';

type StyledProps = Pick<Props, 'toggled'>;

export const StyledPopover = styled.div<StyledProps>(({ toggled }) => [
	tw`relative`,
	toggled &&
		css`
			z-index: 5555556;
		`,
]);
