import 'twin.macro';

import { ArrowLeft } from '@cheqroom/icons';
import { Logo } from '@cheqroom/illustrations';
import { Banner, Button, Form, Heading, IconButton, Illustration, Stack, Text, TextStyle } from '@cheqroom/ui';
import { FC, FormEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Step } from '../../App';
import { StyledCard } from '../../App.styles';
import { useSendResetPasswordEmail } from './api.hooks';

export interface ForgotPasswordStepLocationState {
	state?: {
		error?: string;
		email?: string;
	};
}

export const ForgotPasswordStep: FC = () => {
	const navigateTo = useNavigate();
	const { state } = useLocation() as ForgotPasswordStepLocationState;
	const { email: emailFromState, error } = state || {};
	const { t } = useTranslation();

	const [params] = useSearchParams();
	const emailFromParams = params.get('email');

	const email = emailFromState || emailFromParams;

	const { mutate: sendResetPasswordEmail, status, error: resetPasswordError } = useSendResetPasswordEmail();

	const statusErrorMessage = (): string | undefined | null => {
		if (resetPasswordError === null) {
			return undefined;
		} else {
			switch (resetPasswordError?.status) {
				case 429:
					return t('forgot_password_step.too_many_attempts');
				default:
					return t('forgot_password_step.error');
			}
		}
	};

	const bannerErrorMessage = statusErrorMessage();

	if (!email) {
		return <Navigate to={Step.ENTER_EMAIL} />;
	}

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		sendResetPasswordEmail({ email });
	};

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" vertical spacing="tight">
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1">{t('forgot_password_step.header')}</Heading>

					<Text align="center">{t('forgot_password_step.subtext')}</Text>
				</Stack>

				<Form onSubmit={handleSubmit}>
					{error && <Banner variation="error">{error}</Banner>}

					{bannerErrorMessage && <Banner variation="error">{bannerErrorMessage}</Banner>}

					{status === 'success' && (
						<Banner variation="success">
							<Trans
								components={[<TextStyle key="0" variation="strong" />]}
								i18nKey="forgot_password_step.email_sent"
								values={{ email }}
							/>
						</Banner>
					)}

					<Stack spacing="loose" align="center">
						<IconButton
							aria-label={t('forgot_password_step.back')}
							onClick={() => navigateTo(Step.ENTER_EMAIL, { state: { email } })}
							rounded
							source={ArrowLeft}
							title={t('forgot_password_step.back')}
							variation="secondary"
						/>

						<Text>{email}</Text>
					</Stack>

					{status === 'success' ? (
						<Button onClick={() => navigateTo(Step.ENTER_EMAIL)} size="medium" type="button">
							{t('forgot_password_step.go_to_login')}
						</Button>
					) : (
						<Button size="medium" type="submit" loading={status === 'loading'}>
							{t('forgot_password_step.reset_password')}
						</Button>
					)}
				</Form>
			</Stack>
		</StyledCard>
	);
};
