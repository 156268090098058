import 'twin.macro';

import { ArrowRight } from '@cheqroom/icons';
import { Avatar, Icon, Skeleton, Stack, Text } from '@cheqroom/ui';
import { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Workspace as TWorkspace } from '../../api/discovery.api';

type Props = NavLinkProps & {
	workspace: Pick<TWorkspace, 'id' | 'logo' | 'name'>;
	ariaLabel: string;
};
interface Composition {
	Skeleton: typeof WorkspaceSkeleton;
}

const WorkspaceSkeleton = () => (
	<Stack justify="between" align="center" tw="no-underline px-4 py-3 rounded-md border border-gray-200">
		<Stack justify="between" align="center" spacing="loose">
			<Skeleton tw="rounded-full" width="40px" height="40px" />
			<Skeleton height="20px" width="150px" />
		</Stack>
	</Stack>
);

export const Workspace: FC<Props> & Composition = ({ workspace, ...props }) => {
	return (
		<NavLink
			{...props}
			tw="no-underline px-4 py-3 rounded-md border border-gray-200 focus:outline-none focus:ring-4 focus:ring-indigo-100"
		>
			<Stack justify="between" align="center">
				<Stack justify="between" align="center" spacing="loose">
					<Avatar url={workspace.logo} name={workspace.name} />
					<Text weight="semibold">{workspace.name}</Text>
				</Stack>

				<Icon source={ArrowRight} tw="text-gray-500" />
			</Stack>
		</NavLink>
	);
};
Workspace.Skeleton = WorkspaceSkeleton;
