import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

await i18n
	.use(initReactI18next)
	.use({
		type: 'backend',
		read<Namespace extends string>(
			language: 'en',
			namespace: Namespace,
			callback: (errorValue: unknown, translations: unknown) => void
		) {
			// Dynamically import the translations through Webpack
			import(/* webpackChunkName: "translation-[request]" */ `../../public/locales/${language}/${namespace}.json`)
				.then((resources) => callback(null, resources))
				.catch((error) => callback(error, null));
		},
	})
	.init({
		debug: process.env.ENVIRONMENT !== 'production',
		lng: 'en',
		fallbackLng: 'en',
		supportedLngs: ['en'],
		ns: ['login'],
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
