import { FC, ReactNode } from 'react';

import Content from './Content/Content';
import Footer from './Footer/Footer';
import { StyledPage } from './Page.styles';

interface PageComposition {
	Content: typeof Content;
	Footer: typeof Footer;
}

export const Page: FC<{ children?: ReactNode }> & PageComposition = ({ children, ...props }) => (
	<StyledPage {...props}>{children}</StyledPage>
);

Page.Content = Content;
Page.Footer = Footer;
