import tw, { styled } from 'twin.macro';

import { getColorStyles } from '../../util/color-calculator';
import { Props, Size } from './Avatar';

const getSize = (size: Size) => {
	switch (size) {
		case 'extra-small':
			return tw`w-6 min-w-6 h-6 min-h-6 text-xs`;
		case 'small':
			return tw`w-8 min-w-8 h-8 min-h-8 text-sm`;
		case 'medium':
			return tw`w-10 min-w-10 h-10 min-h-10 text-base`;
		case 'large':
			return tw`w-12 min-w-12 h-12 min-h-12 text-base`;
	}
};

export const StyledAvatar = styled.span<Pick<Props, 'color' | 'size' | 'url' | 'type'>>(
	({ color, size = 'medium', url, type }) => [
		tw`flex items-center justify-center overflow-hidden`,
		tw`font-semibold text-white leading-none`,
		!url && getColorStyles(color),
		// TODO: Get consistent color pattern for icons (square seems to have a different rule according to the designs)
		type === 'circle' ? tw`rounded-full` : tw`rounded bg-gray-100`,
		getSize(size),
	]
);
