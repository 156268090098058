import { useSessionStorage } from '@cheqroom/hooks';
import { ArrowLeft, Check, Error } from '@cheqroom/icons';
import { Logo } from '@cheqroom/illustrations';
import {
	Button,
	ButtonGroup,
	Form,
	Heading,
	Icon,
	Illustration,
	Link as UILink,
	LoadingSpinner,
	Stack,
	Text,
	TextStyle,
} from '@cheqroom/ui';
import { joiResolver } from '@hookform/resolvers/joi';
import { TFunction } from 'i18next';
import Joi from 'joi';
import { ClipboardEvent, FC, MouseEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { Navigate, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Step } from '../../App';
import { StyledCard, StyledOTPInputField } from '../../App.styles';
import { useExchangeCode, useInitiateAuthentication } from './api.hooks';

const toLoginHelpArticle = () =>
	'https://help.cheqroom.com/en/articles/3277290-why-can-t-i-log-in-to-my-cheqroom-account';

interface CodeVerificationFormData {
	code: string;
}

const codeVerificationValidationSchema = (t: TFunction) =>
	Joi.object<CodeVerificationFormData>({
		code: Joi.string()
			.trim()
			.required()
			.pattern(/^[0-9]{6}$/)
			.messages({
				'string.empty': t('code_verification_step.validation.code.required'),
				'string.pattern.base': t('code_verification_step.validation.code.invalid_format'),
			}),
	});

export const CodeVerificationStep: FC = () => {
	const navigateTo = useNavigate();
	const [emailFromSession, saveEmailInSession] = useSessionStorage<string>('email');
	const { t } = useTranslation('login');

	const { mutate: initiateAuthentication, status: initiationAuthenticationStatus } = useInitiateAuthentication();
	const { mutate: exchangeCode, status } = useExchangeCode({
		onError: () => {
			setError('code', { message: t('code_verification_step.validation.code.invalid_expired') });
		},
	});

	const { control, setError, handleSubmit, setValue, reset } = useForm<CodeVerificationFormData>({
		resolver: joiResolver(codeVerificationValidationSchema(t)),
		defaultValues: {
			code: '',
		},
	});

	if (!emailFromSession) {
		return <Navigate to={Step.ENTER_EMAIL} />;
	}

	const onSubmit = handleSubmit(({ code }) => {
		exchangeCode({ email: emailFromSession, code });
	});

	const onPaste = ({ clipboardData }: ClipboardEvent<HTMLFormElement>) => {
		setValue('code', clipboardData?.getData('Text'));
		void onSubmit();
	};

	const resendEmail = (e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		initiateAuthentication({ email: emailFromSession });
		reset();
	};

	const isLoading = status === 'loading';

	return (
		<StyledCard sectioned>
			<Stack tw="gap-6" vertical>
				<Stack align="center" spacing="tight" vertical>
					<Illustration source={Logo} tw="mb-4" />

					<Heading element="h1" align="center">
						{t('email_step.check_mail')}
					</Heading>

					<Text align="center" tw="whitespace-pre-line">
						<Trans
							i18nKey="email_step.email_sent"
							values={{ email: emailFromSession }}
							components={[
								<TextStyle key="0" variation="strong">
									placeholder
								</TextStyle>,
							]}
						/>
					</Text>
				</Stack>

				<hr tw="border-gray-200" />

				<Form id="code-verification-form" onSubmit={onSubmit} onPasteCapture={onPaste} tw="items-center">
					<Text align="center" tw="whitespace-pre-line">
						{t('code_verification_step.code_subtext')}
					</Text>

					<Controller
						control={control}
						name="code"
						render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
							<Stack vertical spacing="tight" tw="max-w-[280px]">
								<OtpInput
									{...rest}
									value={value}
									onChange={onChange}
									numInputs={6}
									renderInput={({ style, ...inputProps }) => (
										<StyledOTPInputField
											{...inputProps}
											maxLength={1}
											type="number"
											inputMode="numeric"
											placeholder="0"
											error={!!error}
										/>
									)}
									shouldAutoFocus
									containerStyle={tw`flex justify-between gap-2`}
									inputStyle=""
								/>
								{error && (
									<Text size="small">
										<TextStyle variation="negative">
											<Stack align="center" element="span" spacing="extraTight">
												<Icon source={Error} tw="text-red-500" size="small" />
												{error.message}
											</Stack>
										</TextStyle>
									</Text>
								)}
							</Stack>
						)}
					/>

					<Stack align="center" spacing="tight">
						<Text size="small" color="subdued" align="center" tw="whitespace-pre-line">
							<Trans
								i18nKey="code_verification_step.resend_email"
								components={[
									<UILink inheritSize key="0" url="#" onClick={resendEmail}>
										placeholder
									</UILink>,
									<UILink inheritSize key="1" external url={toLoginHelpArticle()}>
										placeholder
									</UILink>,
								]}
							/>
						</Text>

						<ResendEmailStatusIndicator status={initiationAuthenticationStatus} />
					</Stack>

					<ButtonGroup tw="w-full">
						<Button
							variation="secondary"
							leftIcon={ArrowLeft}
							onClick={() => {
								saveEmailInSession('');
								navigateTo(Step.ENTER_EMAIL, { state: { email: emailFromSession } });
							}}
						>
							{t('code_verification_step.back')}
						</Button>

						<Button tw="flex-1" type="submit" disabled={isLoading} loading={isLoading}>
							{t('code_verification_step.verify_email')}
						</Button>
					</ButtonGroup>
				</Form>
			</Stack>
		</StyledCard>
	);
};

const ResendEmailStatusIndicator: FC<{ status: 'loading' | 'success' | 'error' | 'idle' }> = ({ status }) => {
	if (status === 'loading') return <LoadingSpinner size="small" />;

	if (status === 'success') return <Icon source={Check} size="small" tw="text-green-500" />;

	return null;
};
